<template>
  <div class="min-h-[50px]">
    <RikazGrid name="material" :items="materials">
      <template #card="{ forKey, data }">
        <MaterialsCard
          :key="`material-grid-item-${forKey}`"
          class="transition-all duration-500 transform hover:scale-105"
          :material-props="( data as Material )"
          :wishlist="wishlist"
          :is-delivering="isDelivering"
          :branch-id-initail="branchId"
          @toggle-wishlist="reset"
        />
      </template>
    </RikazGrid>

    <ClientOnly>
      <button
        v-if="manual && materials.length >= itemsPerPage"
        :aria-label="t('view_more', { of: t('of'), name: t('materials') })"
        class="block mx-auto"
        :disabled="loadState == 'done'"
        @click="manualFetch"
      >
        <div
          v-if="loadState == 'done'"
          class="opacity-80 classButton cursor-default"
        >
          {{ t('loaded') }}
        </div>
        <div
          v-else
          class="hover:bg-button_color classButton text-text_alt_color bg-nav_color"
        >
          {{ t('load_more') }}
        </div>
      </button>

      <MevcutInfiniteScroll
        v-else
        class="my-2"
        :has-initial-cached-materials="materials.length>0"
        :infinite-id="infiniteId"
        @load-more="loadMore"
      />
    </ClientOnly>
  </div>
</template>

<script setup lang="ts">
import { useToast } from 'vue-toastification'
import getAllRoutes from '~/composables/AppApiRoutes'
import type { LoadState } from '~/composables/useInfiniteScroll'
import type { Material } from '~~/composables/useMenuModel'
const props = withDefaults(
  defineProps<{
    itemsPerPage?: number
    fetchRoute?: string
    fetchParams?: Record<string, any>
    withQueryParams?: boolean
    isDelivering?: boolean
    wishlist?: boolean
    keyCache?: string
    manual?: boolean
    branchId?: number
  }>(),
  {
    itemsPerPage: 10,
    fetchParams: () => ({}),
    withQueryParams: false,
    wishlist: false,
    fetchRoute: getAllRoutes().materialsRoutes.shippingRoutes.materialsByServer,
    manual: false,
    keyCache: undefined,
    branchId: undefined
  }
)
const { cacheMaterials, getMaterials } = useCacheSession()
const toast = useToast()
const materials = ref<Material[]>(
  props.keyCache ? [...getMaterials(props.keyCache)] : []
)

const loadState = ref<LoadState>()
const route = useRoute()
const { t } = useI18n()

const { infiniteId, scrollFetch, loadMore, reset } = useInfiniteScroll(
  materials.value.length,
  props.itemsPerPage,
  fetchMaterials,
  appendMaterials,
  clearMaterials
)

if (!materials.value.length) {
  await manualFetch()
}

if (props.withQueryParams) {
  watch(
    () => route.query,
    () => reset()
  )
}

async function fetchMaterials(state: ScrollState) {
  let queryParams = {}
  if (props.withQueryParams) {
    queryParams = { ...route.query }
  }

  try {
    const { data, error } = await useBasicFetch<ApiResponse<[]>>(props.fetchRoute, {
      query: {
        offset: `${state.offset}`,
        limit: `${state.limit}`,
        ...queryParams,
        ...props.fetchParams
      }
    })

    if (error?.value) {
      throw error.value
    }

    return data.value
  } catch (error) {
    if (process.client) {
      toast.error(t('fetch_failed', { data: t('materials') }), {
        timeout: 1000
      })
    }

    if ((error as any)?.data?.data) {
      return (error as any).data as ApiResponse<[]>
    } else {
      return {
        data: [],
        message: t('fetch_failed', { data: t('materials') })
      } as ApiResponse<[]>
    }
  }
}

function appendMaterials(data: []) {
  materials.value = [...materials.value, ...data]
  if (data.length && props.keyCache) {
    cacheMaterials(props.keyCache, materials.value)
  }
}

function clearMaterials() {
  materials.value = []

  loadState.value = 'load'
}

async function manualFetch() {
  loadState.value = await scrollFetch()
}
</script>
<style>
.classButton {
  @apply border border-button_color mx-auto  rounded-lg px-4 pt-2 pb-1 my-2;
}
</style>
